<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent>
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="close" />
        {{ `Terapias complementares` }}
      </v-card-title>
      <v-card-text class="my-5">
        <property-value property="Nome" :value="selected.name" />
        <v-switch
          v-model="hidePatient"
          :value="hidePatient"
          label="Esconder ao paciente na diagnose"
        />
        <comments v-model="comments" />
      </v-card-text>
      <div class="mt-5 d-flex flex-column">
        <v-btn :color="buttonColor" x-large @click="onClick">
          {{
            alreadySelected
              ? "Remover do Diagnóstico"
              : "Adicionar ao Diagnóstico"
          }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    comments: () =>
      import("@/domains/therapy/shared/presentation/components/Comments"),
  },
  data: () => ({
    comments: {},
    hidePatient: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.complementaryTherapy;
      },
      set(value) {
        this.$store.commit("dialogs/setComplementaryTherapy", value);
      },
    },
    selected() {
      return this.$store.state.complementaryTherapies.selected;
    },
    alreadySelected() {
      return this.$store.state.diagnosis.diagnose.complementarTherapies.some(
        (item) => item.complementarTherapy.id === this.selected.id
      );
    },
    buttonColor() {
      return this.alreadySelected ? "error" : "secondary";
    },
    onClick() {
      return this.alreadySelected
        ? this.removeFromDiagnose
        : this.addToDiagnose;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    async addToDiagnose() {
      const { therapistComments, patientComments } = this.comments;
      const value = {
        complementarTherapy: this.selected,
        nameOtherTherapy: "",
        therapistComments,
        patientComments,
        hidePatient: this.hidePatient,
      };
      try {
        this.$store.commit("diagnosis/addComplementaryTherapy", value);
        this.$toasted.global.success({
          message: "Terapia complementar adicionada ao diagóstico",
        });
        this.dialog = false;
      } catch (error) {
        this.$toasted.global.error({
          message: "Erro interno ao adicionar ao diagnóstico",
        });
      }
    },
    async removeFromDiagnose() {
      try {
        const value = {
          complementarTherapy: this.selected,
          nameOtherTherapy: "",
        };
        this.$store.commit("diagnosis/removeComplementaryTherapy", value);
        this.$toasted.global.success({
          message: "Terapia complementar removida do diagóstico",
        });
        this.dialog = false;
      } catch (error) {
        this.$toasted.global.error({
          message: "Erro interno ao remover do diagnóstico",
        });
      }
    },
  },
};
</script>

<style scoped></style>
